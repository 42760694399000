.SortableTaskTreeSortablePreview {
  .rstcustom__row {
    width: 100%;
    .rstcustom__rowLabel {
      width: 100%;
    }
    .rstcustom__rowLabel > span {
      position: absolute;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
