@import "variables";
@import "~bootstrap/scss/bootstrap";

.Toastify__toast {
  font-family: $font-family-sans-serif;
  border-radius: $border-radius;
}

.Toastify__toast--success {
  background: $success;
}

.Toastify__toast--error {
  background: $danger;
}