@import "../../styles/variables";

.long-border {
  border-bottom: 1px solid $body-color;
  position: sticky;
  margin-left: -$page-padding;
  margin-right: -$page-padding;
  box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 10;
  transition: top $bar-transition-time ease-in-out;
  top: $wp-navbar-height + 34px;
  &.with-toolbar {
    top: $wp-navbar-height + $wp-toolbar-height-collapsed + 34px;
  }
  &.with-tabbar {
    top: $wp-navbar-height + $wp-tabbar-height + 34px;
  }
  &.hidden {
    visibility: hidden;
  }
}