@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../styles/variables";
@import "~bootstrap/scss/mixins";

.main-layout {
  padding-top: $wp-navbar-height;

  // transition: padding-top $bar-transition-time ease-in-out
  //   $elements-transition-time, padding-bottom $bar-transition-time ease-in-out
  //   $elements-transition-time;
  &.with-toolbar {
    padding-top: $wp-navbar-height + $wp-toolbar-height-collapsed;
  }

  &.with-tabbar,
  &.with-landmarks {
    padding-top: $wp-navbar-height + $wp-tabbar-height;
  }

  &.with-toolbar.with-tabbar {
    padding-top: $wp-navbar-height + $wp-tabbar-height + $wp-toolbar-height-collapsed;
  }

  &.with-toolbar.with-landmarks {
    padding-top: $wp-navbar-height + $wp-toolbar-height-collapsed + $wp-tabbar-height;
  }

  &.with-tabbar.with-landmarks {
    padding-top: $wp-navbar-height + $wp-tabbar-height + $wp-tabbar-height + 2;
  }

  &.with-toolbar.with-tabbar.with-landmarks {
    padding-top: $wp-navbar-height + $wp-tabbar-height + $wp-toolbar-height-collapsed + $wp-tabbar-height + 2;
  }

  &.with-bottombar {
    padding-bottom: $wp-bottombar-height;
  }

  .layout-content {
    transition: padding $bar-transition-time ease-in-out;
    min-width: 1100px;
  }

  .main-bar {
    height: $wp-navbar-height;
    background-color: $wp-navbar-color;
    display: grid;
    grid-template-rows: $wp-navbar-height;
    grid-template-columns: 2fr 1fr 2fr;
    position: fixed;
    z-index: 1030;
    top: 0;
    left: 0;
    right: 0;

    .main-navigation {
      display: flex;
      flex-direction: row;
      height: 100%;

      .main-navigation-item {
        color: inherit;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        height: 100%;
        align-items: center;
        padding: 0 22px;
        border-bottom: $border3 solid transparent;

        &.home {
          padding: 0 18px;

          &.active {
            background-color: $primary;
            color: $white;
          }
        }

        &.active {
          // border-bottom: $border3 solid $primary;
          background-color: rgba($primary, .20);
        }

        &:hover {
          background-color: $primary15;
          color: $dark;
        }
      }
    }

    .main-bar-alert {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .main-bar-tools {
      justify-self: end;
      display: flex;
      flex-direction: row;
      align-items: center;

      .org-indicator {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: transparent;
        margin-left: 6px;
        margin-right: 6px;

        &.is-current-org {
          background-color: $primary;
        }
      }

      & .active {
        border-bottom: $border3 solid $primary;
      }
    }
  }

  &:not(.with-toolbar):not(.with-landmarks) {
    .main-bar {
      border-bottom: 1px solid $wp-toolbar-color;
    }
  }

  .right-pane {
    position: fixed;
    z-index: 999;
    top: $wp-navbar-height;
    bottom: 0;
    right: 0;
    width: $wp-drawer-width;

    transition: top $bar-transition-time ease-in-out $elements-transition-time,
      right $bar-transition-time ease-in-out;

    .right-pane-wrapper {
      height: 100%;
    }

    .right-pane-content {
      background-color: white;
      border-left: 1px solid black;
      margin-left: 13px;
      height: 100%;
      // padding-right: 12px;
      // padding-top: 20px;
      // padding-bottom: 20px;
      // padding-left: 11px;
    }

    .right-pane-collapse {
      position: absolute;
      top: 20px;
      left: 1px;
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .overview-panel {
    position: fixed;
    z-index: 999;
    top: $wp-navbar-height;
    bottom: 0;
    right: 0;

    transition: top $bar-transition-time ease-in-out $elements-transition-time,
      right $bar-transition-time ease-in-out;

    .overview-panel-wrapper {
      height: 100%;
    }

    .overview-panel-content {
      background-color: white;
      border-left: 1px solid black;
      margin-left: 13px;
      height: 100%;
    }

    .right-pane-collapse {
      position: absolute;
      top: 20px;
      left: 1px;
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transform-origin: center center;
      transition: transform $bar-transition-time linear;
    }

    &:not(.is-open) {
      .right-pane-collapse {
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .right-pane {
      width: $wp-drawer-width-xl;
    }
  }

  &.with-toolbar {

    .right-pane,
    .leftbar,
    .overview-panel {
      top: $wp-navbar-height + $wp-toolbar-height-collapsed;
    }


  }

  &.with-tabbar.with-landmarks {

    .right-pane,
    .leftbar,
    .overview-panel {
      top: $wp-navbar-height + $wp-tabbar-height + $wp-tabbar-height;
    }
  }

  &.with-tabbar {

    .right-pane,
    .leftbar,
    .overview-panel {
      top: $wp-navbar-height + $wp-tabbar-height;
    }
  }

  &.with-bottombar {

    .right-pane,
    .leftbar,
    .overview-panel {
      bottom: $wp-bottombar-height;
    }
  }

  .right-pane-collapsed {
    right: -$wp-drawer-width;
  }

  @include media-breakpoint-up(xxl) {
    .right-pane-collapsed {
      right: -$wp-drawer-width-xl;
    }
  }

  .toolbar {
    height: $wp-toolbar-height-collapsed;
    background-color: $wp-toolbar-color;
    // transform: translateZ(0);
    // transition: height $bar-transition-time ease-in-out
    //   $elements-transition-time;
    position: fixed;
    z-index: 999;
    top: $wp-navbar-height;
    left: 0;
    right: 0;

    // .toolbar-title {
    //   transition: opacity $bar-transition-time ease-in-out,
    //     height $bar-transition-time ease-in-out $elements-transition-time,
    //     margin-top $bar-transition-time ease-in-out $elements-transition-time,
    //     margin-bottom $bar-transition-time ease-in-out $elements-transition-time,
    //     visibility 0s ease-in-out $bar-transition-time +
    //       $elements-transition-time;
    //   visibility: hidden;
    //   opacity: 0;
    //   margin: 0 82px 0 82px;
    //   overflow: hidden;
    //   height: 0;
    // }

    .toolbar-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .toolbar-icon {
      width: 88px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 12px;
      // transition: width $bar-transition-time ease-in-out
      //   $elements-transition-time;

      svg {
        width: 26px;
        height: 26px;
        margin-top: 0;
        margin-bottom: 6px;
        margin-left: 16px;
        margin-right: 16px;
        // transform: translateZ(0);
        // transition: width $bar-transition-time ease-in-out
        //     $elements-transition-time,
        //   height $bar-transition-time ease-in-out $elements-transition-time,
        //   margin $bar-transition-time ease-in-out $elements-transition-time;
      }

      &:hover {
        color: $primary;
      }

      .toolbar-icon-text {
        text-align: center;
        overflow: hidden;
        line-height: 1.1;
        font-size: 0.9em;
        // visibility: visible;
        // opacity: 0;
        // transform: translateZ(0);
        // transition: opacity $bar-transition-time ease-in-out,
        //   visibility 0s linear $bar-transition-time;
      }
    }

    .toolbar-divider {
      width: 1px;
      height: 50px;
      margin-top: 18px;
      margin-left: 16px;
      margin-right: 16px;
      background-color: $separator;
    }
  }

  //TABBAR
  .tabbar {
    height: $wp-tabbar-height;
    background-color: $wp-toolbar-color;
    position: fixed;
    z-index: 999;
    top: $wp-navbar-height;
    left: 0;
    right: 0;

    .tabbar-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .tabbar-tab {
      color: inherit;
      text-decoration: none;
      display: flex;
      min-width: 120px;
      padding: 0 18px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-bottom: $border2 solid transparent;

      &:hover {
        color: $primary;
      }

      &.active {
        border-bottom: $border2 solid $primary;
        color: $primary;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 8px;
      }

      .tabbar-tab-text {
        text-align: left;
      }
    }

    .tabbar-divider {
      width: 1px;
      height: 20px;
      margin-top: 12px;
      margin-bottom: 12px;
      background-color: $separator;
    }
  }

  .tabbar-landmarks {
    height: $wp-tabbar-height;
    background-color: rgb(226, 227, 246); // rgba($primary, .20);
    position: fixed;
    z-index: 999;
    top: $wp-navbar-height;
    left: 0;
    right: 0;

    .tabbar-landmarks-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .tabbar-landmarks-tab {
      color: inherit;
      text-decoration: none;
      display: flex;
      min-width: 150px;
      padding: 0 32px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-bottom: $border2 solid transparent;

      &:hover {
        color: $primary;
      }

      &.active {
        color: $primary;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 8px;
      }

      .tabbar-landmarks-tab-text {
        text-align: left;
      }
    }

    .tabbar-divider {
      width: 1px;
      height: 20px;
      margin-top: 12px;
      margin-bottom: 12px;
      background-color: $separator;
    }
  }

  //BOTTOMBAR
  .bottombar {
    height: $wp-bottombar-height;
    background-color: $white;
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;
    right: 0;
  }

  //LEFTBAR
  .leftbar {
    width: $wp-bottombar-height;
    background-color: $white;
    position: fixed;
    border: red;
    z-index: 999;
    left: 0;
    top: $wp-navbar-height + $wp-tabbar-height;
    bottom: 0;
  }
}

// @media screen and (min-height: 620px) {
//   .main-layout {
//     &.with-toolbar:not(.top-force-collapse) {
//       padding-top: $wp-navbar-height + $wp-toolbar-height-collapsed + 40;
//       padding-bottom: $wp-toolbar-height-expanded - $wp-toolbar-height-collapsed;
//     }
//     &.with-toolbar.top-expanded:not(.top-force-collapse) {
//       transition: padding-top $bar-transition-time ease-in-out, padding-bottom $bar-transition-time ease-in-out;
//       padding-top: $wp-navbar-height + $wp-toolbar-height-expanded;
//       padding-bottom: 0;
//       .toolbar {
//         height: $wp-toolbar-height-expanded;
//       }
//       .right-pane {
//         transition: top $bar-transition-time ease-in-out,
//           right $bar-transition-time ease-in-out;
//         top: $wp-navbar-height + $wp-toolbar-height-expanded;
//       }
//       .overview-panel {
//         transition: top $bar-transition-time ease-in-out,
//           right $bar-transition-time ease-in-out;
//         top: $wp-navbar-height + $wp-toolbar-height-expanded;
//       }
//       .toolbar {
//         transition: height $bar-transition-time ease-in-out;
//         .toolbar-title {
//           visibility: visible;
//           transform: translate3d(0, 0, 0);
//           opacity: 1;
//           margin: 32px 82px 20px 82px;
//           height: 26px;
//           transition: visibility 0s ease-in-out $elements-transition-time,
//             opacity $bar-transition-time ease-in-out $elements-transition-time,
//             height $bar-transition-time ease-in-out,
//             margin-top $bar-transition-time ease-in-out,
//             margin-bottom $bar-transition-time ease-in-out;
//         }
//         .toolbar-icon {
//           width: 100px;
//           display: flex;
//           flex-direction: column;
//           justify-content: flex-start;
//           align-items: center;
//           transition: width $bar-transition-time ease-in-out;

//           svg {
//             width: 36px;
//             height: 36px;
//             margin-top: 10px;
//             margin-bottom: 6px;
//             transition: width $bar-transition-time ease-in-out,
//               height $bar-transition-time ease-in-out,
//               margin $bar-transition-time ease-in-out;
//           }

//           .toolbar-icon-text {
//             text-align: center;
//             visibility: visible;
//             opacity: 1;
//             transition: visibility 0s ease-in-out $elements-transition-time,
//               opacity $bar-transition-time ease-in-out $elements-transition-time;
//           }
//         }
//       }
//     }
//   }
// }