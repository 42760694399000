@import "../../styles/variables";

.calendarRow {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 5px;
  user-select: none;
}

.calendarCell {
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  &.active {
    background-color: $primary35;
  }

  &.selected, &.active.selected {
    background-color: $primary;
    color: white;
  }
}

.calendarHeader {
  .calendarCell:nth-child(2) {
    width: 180px;
  }
}

.calendarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.calendarDays .calendarCell {
  font-size: 12px;
  height: 16px;
  margin-top: 4px;
  color: #999;
}