.color-picker-swatch {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.color-picker-color {
  border-radius: 2px;
  width: 24px;
  height: 16px;

  &.lg {
    width: 150px;
    height: 17px;
  }

  &.md {
    width: 80px;
    height: 15px;
  }

  &.richtext {
    width: 15px;
    height: 8px;
  }
}

.color-picker-popover {
  position: relative;
}

.color-picker-popup {
  position: absolute;
  z-index: 2;

  &.bottom-picker {
    bottom: 100%;
  }

  &.top-picker {
    top: 100%;
  }

  &.left-picker {
    left: 0;
  }

  &.right-picker {
    right: 0;
  }
}
