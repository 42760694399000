@import "../../styles/variables";

.container {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: $white;

  .symbol-container {
    border-width: 1px;
    border-radius: 12px;
    font-size: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
