.gantt-task-label {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  height: 42px;
  border-right: 1px solid #999;

  &:nth-child(2n) {
    background-color: #f5f5f5;
  }

  .code {
    font-size: 10px;
  }

  .text {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

.overflow-scroll {
  overflow-x: scroll;
}