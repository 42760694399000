@import "../../styles/variables";

.wp-filter-btn {
  color: $black;
  border: 1px solid $black;
  background-color: transparent;
  border-radius: 10px;
  font-size: 13px;
  line-height: 16px;
  padding: 6px 16px;

  &:focus {
    outline: none;
  }
}

.wp-filter-btn-primary {
  &:hover,
  &:focus {
    background-color: $primary15;
    border: 1px solid $primary;
    color: $black;
  }

  &.wp-filter-btn-selected,
  &.wp-filter-btn-selected:hover,
  &.wp-filter-btn-selected:focus {
    background-color: $primary50;
    border: 1px solid $primary;
    color: $white;
    outline: none;
  }
}
