@import "../../../styles/variables";

.left-container {
  width: 350px;
  border-right: 1px solid $separator;
  background-color: $gray-bg-light;
  margin-top: 4px;
}

.preview-card-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .marker {
    width: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  
    &.current {
      background-color: $primary;
    }
  
    &.not-current {
      background-color: transparent;
    }
  }

  &:hover {
    color: $primary;
  }
}