@import "../../../styles/variables";

.member-card {
  border: 1px solid $separator;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  margin: 8px;
  padding: 8px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 4px;
    object-fit: cover;
    object-position: center center;
  }

  &.placeholder {
    padding-bottom: 33px;

    &:hover {
      cursor: pointer;
      background-color: $gray-light;
    }
  }

  .permissions {
    font-size: 90%;
    font-style: italic;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .username {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
  }
}

.actions {
  border-top: 1px solid $separator;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  margin: 4px -8px -8px -8px;

  &>div {
    flex: 1 1 0%;
    text-align: center;
    padding: 2px;
    cursor: pointer;

    &:hover {
      background-color: $gray-light;
    }

    &:first-child {
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
    }

    &:not(:last-child) {
      border-right: 1px solid $separator;
    }
  }
}