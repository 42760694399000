@import "../../styles/variables";

.layout-left {
  width: 300px;
  background: $wp-toolbar-color;
  border-right: 1px solid $separator;
}

.tabbar-tab {
  color: inherit;
  text-decoration: none;
  display: flex;
  padding: 0 32px;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: $border2 solid transparent;
  text-decoration: none;

  &:hover {
    color: $primary;
  }

  &.active {
    border-bottom: $border2 solid $primary;
    color: $primary;
  }

  &:hover {
    text-decoration: none;
  }
}

.tabbar-tab-disabled {
  color: $gray-light;
  pointer-events: none;
}

.tabbar-divider {
  width: 1px;
  height: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: $separator;
}
.tabbar-divider-invisible {
  width: 0px;
  height: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: transparent;
}

.org-sign {
  width: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  &.current-org {
    background-color: $primary;
  }

  &.not-current-org {
    background-color: transparent;
  }
}

.save-bar {
  background-color: $white;
  border-top: 1px solid $separator;
  box-shadow: 0 -1px 3.5px 0 rgba($black, 0.3);
  z-index: 2;
}
