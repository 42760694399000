@import "../../styles/variables";

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;

  &.xs {
    width: 16px;
    height: 16px;

    &.allow-hover {
      width: 24px;
      height: 24px;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  &.sm {
    width: 24px;
    height: 24px;

    &.allow-hover {
      width: 40px;
      height: 40px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &.md {
    width: 32px;
    height: 32px;
    
    &.allow-hover {
      width: 60px;
      height: 60px;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  &.allow-hover {
    &:hover,
    &.active {
      background-color: $primary15;

      img {
        border: 1px solid $primary;
      }
    }
  }

  &.circle {
    border-radius: 50%;

    img {
      border-radius: 50%;
      border: 1px solid $black;
      object-fit: cover;
      background-color: $white;
    }
  }

  &.square {
    border-radius: 5px;

    img {
      border-radius: 5px;
      border: 1px solid $black;
      object-fit: cover;
      background-color: $white;
    }
  }
}
