@import "../../../../styles/variables";

.resource-row {
  &:nth-of-type(odd) {
    background-color: $odd;
  }

  &:nth-of-type(even) {
    background-color: $even;
  }
}

.resource-header {
  cursor: pointer;
  border-bottom: 1px solid $separator;
  display: flex;

  &.no-resource-border {
    border-bottom: 1px solid transparent !important;
  }
}

.header-container {
  &:hover {
    background-color: dynamic-theme(.15);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.resource-header-section {
  display: flex;
  align-items: center;

  &.cost {
    width: 150px;
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    justify-content: flex-end;
  }
}

.resource-header-section-title {
  flex: 1;
  min-width: 0%;
}

.resource-header-section-money {
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $white;
  background-color: dynamic-theme(.50);
  width: 180px;
}

.resource-detail-card {
  background-color: $white;
  border-radius: 14px;
  margin: 12px 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;

  .resource-description {
    width: 50%;

    &.long {
      width: 100% !important;
    }
  }

  .resource-unit-table {
    width: 50%;
  }
}
