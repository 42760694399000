.frag-animator {
    position: relative;
    height: 380px;
    overflow: hidden;
  
    .add-frag-select-template,
    .add-frag-set-info {
      position: absolute;
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: transform 500ms ease-in-out;
    }
  
    &.pick-template {
      .add-frag-select-template {
        transform: translateX(0);
      }
      .add-frag-set-info {
        transform: translateX(100%);
      }
    }

    &.set-info {
      .add-frag-select-template {
        transform: translateX(-100%);
      }
      .add-frag-set-info {
        transform: translateX(0);
      }
    }
  }