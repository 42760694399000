.icon {
  width: 24px;
  height: 24px;
}

.wrapper {
  position: relative;
  cursor: pointer;
}

.badge {
  position: absolute;
  line-height: 1;
  font-size: 10px;
  top: 0;
  left: calc(100% - 10px);
  transform: translateY(-4px);
  background-color: var(--danger);
  border-radius: 7px;
  color: white;
  padding: 2px 4px;
  min-width: 14px;
  text-align: center;
}