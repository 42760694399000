@import "../../styles/variables";

.wp-textarea {
  border-radius: 10px;
  border: $border-width solid $separator;
  &[disabled] {
    background-color: $disabled;
  }
  &:focus-within {
    background-color: $primary15;
    border: $border-width solid transparent !important;
  }
}
