@import "../../../styles/variables";

@keyframes dash {
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  color: $primary;
}

.button {
  stroke: $primary;
  fill: $white;
  stroke-width: 3;
  cursor: pointer;
}

.arc {
  stroke: $white;
  fill: none;
  stroke-width: 4;
  animation: dash 3s linear infinite;
}

.icon {
  pointer-events: none; 
}