@import "../../styles/variables";

.panel {
  background-color: $gray-bg;
  border-radius: 12px;
  padding: 16px 20px;
  margin-bottom: 12px;

  h3 {
    margin-bottom: 0;
  }

  i {
    font-size: smaller;
  }

  p {
    margin-bottom: 0;
    min-height: 1em;
  }
}

.read-icon {
  &.unread {
    cursor: pointer;
  }
  margin-right: 20px;
}
