.frag-text-wrapper {
  padding-bottom: 12px;
  border-radius: inherit;

  & > :first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

  }
  & > :last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
}

.frag-text-editor {
  background-color: white;
  min-height: 100px;
  padding: 0 1em;
}

.dropzone {
  position: relative;
  border: 1px dashed #999999;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    z-index: 1;
  }
  div {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(#333333, 0.5);
    color: white;
  }
  div > p {
    margin: 0 12px;
    text-align: center;
  }
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .image-positioner {
    position: relative;
  }

  .image-tools {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#000000, 0.5);
    color: white;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-tools-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 8px 0;
    }

    .image-tools-action {
      display: inline-block;
      width: 1.75em;
      height: 1.75em;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: rgba(#999999, 0.5);
      }
    }
  }
  &:hover {
    .image-tools {
      display: flex;
    }
  }
}

.image-align {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.image-left {
    justify-content: flex-start;
  }

  &.image-right {
    justify-content: flex-end;
  }
}

.rich-text-editor-link {
  img {
    display: none;
    margin-left: 4px;
    cursor: pointer;
  }
  &:hover {
    text-decoration: none;

    & > span {
      text-decoration: underline;
    }

    img {
      display: inline-block;
    }
  }
}

.templating-decorator-wrapper {
  color: blue;
}
