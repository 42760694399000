@import "../../styles/variables";

.scrolling-viewport {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 0;
  overflow: auto;
  min-width: none;

  &::before {
    content: '';
    display: block;
    width: $page-padding;
    flex: 0 0 $page-padding;
    align-self: stretch;
  }

  &::after {
    content: '';
    display: block;
    width: $page-padding;
    flex: 0 0 $page-padding;
    align-self: stretch;
  }
}

.card {
  width: 28vw;
  margin: 0 10px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 28vw;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .header {
    background-color: $primary;
    color: $white;
    padding: 12px 16px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.33;
    border: 1px solid $primary;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-weight: 600;
    margin: 0;
  }

  .body {
    padding: 12px 24px 16px;

    h3 {
      font-size: 13px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 12px;
      margin-left: -8px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &:nth-child(2n) {
      background-color: $gray-light;
      input {
        background-color: $gray-light !important;
      }
    }

    &:nth-child(2n+1) {
      input {
        background-color: white !important;
      }
    }

    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $separator;

    &:last-child {
      border-bottom: 1px solid $primary;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}