@import "../../../../styles/variables";

.depth-dot {
  margin: 1px 0;
}

.action-icon-container:hover {
  background-color: dynamic-theme(.35);
  border-radius: 50%;
}

.total {
  background-color: dynamic-theme();
  color: white;
  text-align: right;

  & > div {
    border-color: transparent !important;
  }
}

.before-total > div {
  border-color: transparent !important;
}

.total-row {
  border-top: 1px solid $separator;
  border-bottom: 1px solid dynamic-theme();
  background-color: dynamic-theme(.15);
}
