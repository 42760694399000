.title {
  margin-top: 52px;
  font-size: 50px;
  letter-spacing: 10px;
  font-weight: 500;
}

.subtitle {
  margin-top: 24px;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 6px;
}

.artwork1 {
  width: 413px;
  height: 306px;
}

.artwork2 {
  width: 279px;
  height: 274px;
}

.artwork3 {
  width: 600px;
  height: 240px;
  margin-top: 187px;
}

.guy {
  width: 61px;
  height: 61px;
}