.outer {
  width: 100%;
  height: 100%;
  overflow: scroll;
  flex: 1;
}

.sticky-left {
  position: sticky;
  left: 0;
}

.sticky-left-2 {
  position: sticky;
  left: 200px;
}