@import "../../../styles/variables";

.status {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  &.status-member {
    // TODO: Make a var
    background: #2ec4b6;
  }
  &.status-invitation {
    // TODO: Make a var
    background: #f4b67c;
  }
}

.plus-row-btn {
  height: 20px;
  width: 20px;
}

.dropdown-container {
  width: 140px;
}

table.members-table {
  // background: pink;
  th {
    text-transform: uppercase;
  }

  tbody > tr {
    border-bottom: 1px solid $separator;
  }
  tbody > tr:last-child {
    border-bottom: 0;
  }

  td {
    // height: 100px;
    // margin: auto;
    // text-align: center;
    // background: palegoldenrod;
    vertical-align: middle;
  }
}
