.viz-container {
  height: 100px;

  .title {
    width: 150px;
  }

  .resource-container {
    display: flex;
    flex-direction: row;

    .resource {
      border-width: 3px;
      border-style: solid;
    }
  }
  .cost-container {
    border-width: 3px;
    border-style: solid;
    border-color: #333333;
    background-color: #d6d6d6;
    display: flex;
    flex-direction: row;
  }
}

.legend-square {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  margin-right: 10px;
}

.legend-fixed-costs {
  border-color: #333333;
  background-color: #d6d6d6;
}