.styledPage {
  font-family: var(--font) !important;
  [data-text="true"] {
    font-family: var(--font) !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--fontHeadings) !important;
    [data-text="true"] {
      font-family: var(--fontHeadings) !important;
    }
  }
}
