@import "../../styles/variables";

.wp-table {
  .wp-sticky-row {
    position: sticky;
    top: $wp-navbar-height + $wp-toolbar-height-collapsed;
    z-index: 1;
    &.wp-sticky-tabbar {
      top: $wp-navbar-height + $wp-tabbar-height;
    }
  }
  .wp-row:nth-of-type(odd) {
    background-color: $white;
    &.wp-row-active {
      background-color: $primary35;
    }
  }

  .wp-row:nth-of-type(even) {
    background-color: $gray-light;
    &.wp-row-active {
      background-color: $primary35;
    }
  }

  .wp-row:hover {
    background-color: $primary15;
    border-radius: 24px;
    border-color: transparent !important;
  }

  .wp-row-active {
    background-color: $primary35;
    border-radius: 24px;
    border-bottom: 1px solid transparent !important;
  }
}

.wp-cell:not(:last-child) > div {
  border-right: 1px solid $separator;
}

// @media screen and (min-height: 620px) {
//   .wp-table {
//     .wp-sticky-row {
//       position: sticky;
//       top: $wp-navbar-height + $wp-toolbar-height-expanded;
//       transition: top $bar-transition-time ease-in-out;
//       z-index: 1;
//       &.wp-sticky-row-up {
//         transition: top $bar-transition-time ease-in-out
//           $elements-transition-time;
//         top: $wp-navbar-height + $wp-toolbar-height-collapsed;
//       }
//     }
//   }
// }

.wp-row {
  page-break-inside: avoid;
}

.wp-header {
  page-break-inside: avoid;
  page-break-after: avoid;
}


