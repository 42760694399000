.close-edit-panel-icon {
  position: absolute;
  top: 30px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
}
