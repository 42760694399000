@import '../../../../styles/variables';

.frag-animator {
  position: relative;
  height: 365px;
  overflow: hidden;

  .add-frag-modal-choice,
  .add-frag-select-viz,
  .add-frag-select-template,
  .add-frag-select-resource,
  .add-frag-select-task,
  .add-frag-template-info {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 500ms ease-in-out;
  }

  &.pick-element {
    .add-frag-select-viz,
    .add-frag-select-template,
    .add-frag-select-resource,
    .add-frag-select-task,
    .add-frag-template-info {
      transform: translateX(100%);
    }
    .add-frag-modal-choice {
      transform: translateX(0);
    }
  }

  &.pick-viz {
    .add-frag-select-viz {
      transform: translateX(0);
    }
    .add-frag-modal-choice {
      transform: translateX(-100%);
    }
    .add-frag-select-template,
    .add-frag-select-resource,
    .add-frag-select-task,
    .add-frag-template-info {
      transform: translateX(100%);
    }
  }

  &.pick-template {
    .add-frag-select-template {
      transform: translateX(0);
    }
    .add-frag-modal-choice {
      transform: translateX(-100%);
    }
    .add-frag-select-viz,
    .add-frag-select-resource,
    .add-frag-select-task,
    .add-frag-template-info {
      transform: translateX(100%);
    }
  }

  &.template-info {
    .add-frag-template-info {
      transform: translateX(0);
    }
    .add-frag-select-template,
    .add-frag-modal-choice {
      transform: translateX(-100%);
    }
    .add-frag-select-viz,
    .add-frag-select-resource,
    .add-frag-select-task {
      transform: translateX(100%);
    }
  }

  &.pick-resource {
    .add-frag-select-resource {
      transform: translateX(0);
    }
    .add-frag-modal-choice {
      transform: translateX(-100%);
    }
    .add-frag-select-viz,
    .add-frag-select-template,
    .add-frag-template-info,
    .add-frag-select-task {
      transform: translateX(100%);
    }
  }

  &.pick-task {
    .add-frag-select-task {
      transform: translateX(0);
    }
    .add-frag-modal-choice {
      transform: translateX(-100%);
    }
    .add-frag-select-viz,
    .add-frag-select-template,
    .add-frag-template-info,
    .add-frag-select-resource {
      transform: translateX(100%);
    }
  }
}