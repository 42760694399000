@import "../../styles/variables";

.wp-btn {
  font-size: 13px;
  border-radius: 6px;
  outline: none;
  line-height: 16px;

  &:focus {
    outline: none;
  }

  &[disabled],
  &[disabled]:hover {
    background-color: transparent;
    border: 0.8px solid $separator;
    color: $separator;
    cursor: not-allowed;
  }
}

.wp-btn-size-lg {
  padding: 8px 20px;
}

.wp-btn-size-sm {
  padding: 4px 8px;
}

.wp-btn-rounded {
  border-radius: 16px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.wp-btn-primary {
  background-color: transparent;
  border: 0.8px solid $primary;
  color: $primary;

  &:hover,
  &:focus {
    background-color: $primary15;
  }

  &:active,
  &.wp-btn-active {
    background-color: $primary50;
    color: $white;

    &[disabled],
    &[disabled]:hover {
      background-color: $primary50;
      border: 0.8px solid $separator;
      color: $gray-light;
    }
  }
}

.wp-btn-none {
  background-color: transparent;
  border: 0.8px solid transparent;
  color: $black;

  &:hover,
  &:focus {
    background-color: $primary15;
    color: $primary;
    border: 0.8px solid $primary;
  }

  &:active,
  &.wp-btn-active {
    background-color: $primary50;
    border: 0.8px solid $primary;
    color: $white;

    &[disabled],
    &[disabled]:hover {
      background-color: $primary50;
      border: 0.8px solid $separator;
      color: $gray-light;
    }
  }
}

.wp-btn-dark {
  background-color: transparent;
  border: 0.8px solid $black;
  color: $black;

  &:hover,
  &:focus {
    background-color: $primary15;
    color: $primary;
    border: 0.8px solid $primary;
  }

  &:active,
  &.wp-btn-active {
    background-color: $primary50;
    border: 0.8px solid $primary;
    color: $white;

    &[disabled],
    &[disabled]:hover {
      background-color: $primary50;
      border: 0.8px solid $separator;
      color: $gray-light;
    }
  }
}

.wp-btn-danger {
  background-color: transparent;
  border: 0.8px solid $danger;
  color: $danger;

  &:hover,
  &:focus {
    background-color: $danger35;
    color: $danger;
    border: 0.8px solid $danger;
  }

  &:active,
  &.wp-btn-active {
    background-color: $danger50;
    border: 0.8px solid $danger;
    color: $white;

    &[disabled],
    &[disabled]:hover {
      background-color: $primary50;
      border: 0.8px solid $separator;
      color: $gray-light;
    }
  }
}

.wp-btn-white-fill {
  background-color: $white;
  border: 0.8px solid $gray-bg-light;
  color: $black;

  &:hover,
  &:focus {
    background-color: $white;
  }

  &:active,
  &.wp-btn-active {
    background-color: $white;

    &[disabled],
    &[disabled]:hover {
      background-color: $separator;
      border: 0.8px solid $separator;
      color: $gray-light;
    }
  }
}

.button-group {
  .wp-btn {
    border-radius: 0;
    border-right-style: none;
  }

  .wp-btn:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .wp-btn:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right-style: solid;
  }
}