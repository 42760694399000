@import '../../styles/variables';

.dropdown,
.dropdown:hover,
.dropdown:focus,
.dropdown:active {
  border-radius: 6px !important;
  background-color: $white  !important;
  color: $black  !important;
  border: 1px $separator solid !important;
  outline: none !important;
  box-shadow: none !important;

  &.invalid {
    background-color: rgba($danger, .35) !important;
  }

  &.oneline {
    .dropdown-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.disabled {
    background-color: #efefef !important;
    color: #aaa !important;
  }

  &.readonly {
    background-color: #f9f9f9 !important;
  }
}


.dropdown-item:active {
  background-color: $primary50  !important;
  outline: none !important;
  border: none !important;
}

.dropdown-menu {
  max-height: 150px;
  width: 100%;
  overflow-y: auto;
}

.placeholder {
  color: #aaa;
}

.wp-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}