@import "../../../../styles/variables";

.node {
  fill: rgba(white, .20);
  stroke: dynamic-theme();
  stroke-dasharray: 8px;

  &.own-resources {
    fill: dynamic-theme(.15);
    stroke: none;
  }

  &:hover {
    fill: dynamic-theme(.35);
  }
}

.root-node {
  fill: white;
  stroke: dynamic-theme();
  stroke-dasharray: 0;

  &:hover {
    fill: dynamic-theme(.35);
  }
}

.fake-node {
  stroke: none;
  fill: none;
}