@import "../../../styles/variables";

.modal-add-frag {
  height: 128px;
  width: 208px;
  box-shadow: 0 1.5px 2.5px 0 rgba(99, 105, 209, 0.4);
  cursor: pointer;

  .modal-card-header {
    background-color: $primary15;
    border-top-left-radius: $border-radius-xl;
    border-top-right-radius: $border-radius-xl;
    height: 45px;
  }

  &:hover, &.active {
    background-color: $primary15;
    
    .modal-card-header {
      background-color: $primary35;
    }
  }
}

.overview-title {
  transform: rotate(-90deg) translate(-100%, 20px) translateX(-10px);
  transform-origin: left center;
  display: inline-block;
}