@import "../../../styles/variables";

.col-common {
  padding: 10px 8px;
}

.col-title {
  @extend .col-common;
  flex-grow: 4;
  flex-shrink: 0;
  flex-basis: 0;
  min-width: 0;
}

.col-unit {
  @extend .col-common;
  flex-grow: 3;
  flex-shrink: 0;
  flex-basis: 0;
  min-width: 0;
}

.col-quantity {
  @extend .col-common;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 70px;
  width: 70px;
  box-sizing: content-box;
}

.col-cost,
.col-price {
  @extend .col-common;
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 0;
  min-width: 0;
  min-height: 55px;
  &.col-header {
    min-height: 0;
  }
}

.col-action {
  @extend .col-common;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 24px;
  width: 24px;
  box-sizing: content-box;
}
