@import "../../styles/variables";

.editor-area {
  border: 1px solid $separator;
  border-radius: 10px;
  // overflow: hidden;

  .text-area {
    max-height: 200px;
    overflow: auto;

    scrollbar-width: thin;
    scrollbar-color: $separator white; /* scroll thumb & track */

    &::-webkit-scrollbar {
      width: 12px;               /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: white;        /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
      background-color: $separator;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
      border: 3px solid white;  /* creates padding around scroll thumb */
    }
  }
}

.editor-disabled {
  padding-top: 4px;
}

.editor-disabled, .text-disabled {
  background-color: $disabled;
}

.wrapper {
  padding-bottom: 0 !important;
}