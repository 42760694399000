@import "../../styles/variables";

.h-separator {
  border-top: 1px solid $separator;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-separator {
  border-right: 1px solid $separator;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 1px;
}
