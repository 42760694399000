.legend-item {
  border-left-width: 2px;
  border-left-style: solid;
  padding-left: 8px;
}

.legend-line-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding-right: 20px;
}