@import "../../../styles/variables";

.frag-add-divider {
  height: 4px;
  background-color: $primary;
  visibility: hidden;
}

.add-button:hover {
  & ~ .frag-add-divider {
    visibility: visible;
  }
}

.header-1 {
  font-size: 21px;
  font-weight: $font-weight-semibold;
  font-family: var(--fontHeadings);
}

.header-2 {
  font-size: 19px;
  font-weight: $font-weight-semibold;
  font-family: var(--fontHeadings);
}

.header-3 {
  font-size: 17px;
  font-weight: $font-weight-semibold;
  font-family: var(--fontHeadings);
}

.header-4 {
  font-size: 15px;
  font-weight: $font-weight-semibold;
  font-family: var(--fontHeadings);
}

.header-5 {
  font-size: 13px;
  font-weight: $font-weight-semibold;
  font-family: var(--fontHeadings);
}
