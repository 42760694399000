@import "variables";

@function hexToRGBString($hexColor) {
  @return "#{red($hexColor)},#{green($hexColor)},#{blue($hexColor)}";
}

// Boostrap scss
@import "~bootstrap/scss/bootstrap";
@import "./custom_popover.scss";

@import "~rc-slider/assets/index.css";

//patches for imported styles
@import "./patches.scss";

// GENERAL
html,
body {
  height: 100%;
}

@for $i from 1 to 5 {
  .flex-#{$i} {
    flex: $i $i 0%;
    min-height: 0;
    min-width: 0;
  }
}

.min-w-0 {
  min-width: 0;
}

.min-w-25 {
  min-width: 25vw;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

*:focus {
  outline: none;
}

// CURSOR
.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

// FONTS
.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

// TEXTAREA and INPUT
textarea {
  resize: none !important;
  border: none;
  color: $dark;

  &::placeholder {
    color: $placeholder;
  }
}

textarea.resize-vertical {
  resize: vertical !important;
}

input {
  color: $dark;

  &::placeholder {
    color: $placeholder;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.invalid-input {
  background-color: $danger35 !important;
  border: $border-width solid transparent !important;

  &:hover,
  &:active,
  &:focus {
    background-color: $danger35 !important;
    border: $border-width solid transparent !important;
  }
}

.invalid-input-border {
  border: $border-width solid $danger35 !important;

  &:hover,
  &:active,
  &:focus {
    border: $border-width solid $danger35 !important;
  }
}

// NAVBAR
.navbar-active {
  @extend .font-size-navbar;
  color: $primary;
}

.font-size-navbar {
  font-size: $font-size-navbar;
}

// BORDERS
.border-1 {
  border-width: $border1 !important;
}

.border-2 {
  border-width: $border2 !important;
}

.border-3 {
  border-width: $border3 !important;
}

.border-radius-xl {
  border-radius: $border-radius-xl !important;
}

// SVG
svg:hover .fill-circle-on-hover {
  fill: $primary15;
}

.fill-primary {
  fill: $primary;
}

.fill-gray-light {
  fill: $gray-light;
}

// POSITION
.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.top-50 {
  top: 50%;
}

.bottom-50 {
  bottom: 50%;
}

.left-50 {
  left: 50%;
}

.right-50 {
  right: 50%;
}

a.no-link {
  color: inherit;
  text-decoration: none !important;
}

$theme-color-rgb: hexToRGBString($primary);

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  --theme: #{$theme-color-rgb};
}

.text-dynamic-theme {
  color: dynamic-theme() !important;
}

.border-dynamic-theme {
  border-color: dynamic-theme() !important;
}

.bg-dynamic-theme {
  background-color: dynamic-theme() !important;
}

.page-break-inside-avoid {
  page-break-inside: avoid !important;
}

.page-break-after-avoid {
  page-break-after: avoid !important;
}

.page-break-before-avoid {
  page-break-before: avoid !important;
}

.page-break-before-always {
  page-break-before: always !important;
}

@media print {
  .no-printer {
    display: none;
  }
}

// Common
.action-icon-container-primary-hover:hover {
  background-color: $primary35;
  border-radius: 50%;
}

.action-icon-label-container-primary-hover:hover {
  background-color: $primary35;
  border-radius: 6px;
  cursor: pointer;
}

.dropdown-item-primary-active {

  &:active,
  &.active {
    background-color: $primary50 !important;
  }

  &:hover {
    color: $primary !important;
    background-color: $white;
  }

  outline: none !important;
  border: none !important;
}

.icon-disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.font-size-small {
  font-size: 90%;
}

@for $i from 0 to 25 {
  .col-24-#{$i} {
    flex: 0 0 $i/24 * 100%;
    min-width: 0;
  }
}

.prevent-overflow {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.dashed-line {
  border-top: 1px dashed $separator;
}

.line-height-1 {
  line-height: 1;
}

.font-size-label {
  font-size: 12px;
}

// This belongs to document only, but it is used cross fragment
.disclaimer {
  padding: 20px 30px;
  background-color: #e4e4e4;
  text-align: center;
  border-radius: 10px;
}