/* COLORS */

$primary: #6369d1;
$primary10: rgba($primary, 0.1);
$primary15: rgba($primary, 0.15);
$primary30: rgba($primary, 0.3);
$primary35: rgba($primary, 0.35);
$primary50: rgba($primary, 0.5);

$secondary: #2ec4b6;
$secondary10: rgba($secondary, 0.1);
$secondary15: rgba($secondary, 0.15);
$secondary30: rgba($secondary, 0.3);
$secondary35: rgba($secondary, 0.35);
$secondary50: rgba($secondary, 0.5);

$danger: #ff5a5f;
$danger35: rgba($danger, 0.35);
$danger50: rgba($danger, 0.5);

$separator: #bebebe;

$placeholder: #a8a8a8;

$gray-bg: #f4f4f4;
$gray-bg-light: #f2f2f2;

$gray-light: #f8f8f8;

$even: #fafafa;
$odd: #efefef;

$black: #333333;
$white: #ffffff;
$dark: $black;

$disabled: #f9f9f9;

$theme-colors: (
  "primary-10": $primary10,
  "primary-15": $primary15,
  "primary-30": $primary30,
  "primary-35": $primary35,
  "primary-50": $primary50,
  "secondary-10": $secondary10,
  "secondary-30": $secondary30,
  "danger-35": $danger35,
  "danger-50": $danger50,
  "separator": $separator,
  "gray-bg": $gray-bg,
  "gray-bg-light": $gray-bg-light,
  "gray-light": $gray-light,
  "teal": #20c997,
  "cyan": #17a2b8,
  "gray": #6c757d
);

$text-muted: $placeholder;

/* BORDERS */

$border-radius: 4px;
$border-radius-sm: 2px;
$border-radius-lg: 6px;
$border-radius-xl: 10px;
$border-color: $separator;
$border-width: 1px;
$border1: $border-width;
$border2: $border-width * 2;
$border3: $border-width * 3;

/* FONTS */

$font-scale-factor: 13px;

@function px-to-rem($size-in-px) {
  @return $size-in-px / 16px * 1rem;
}

$font-size-base: px-to-rem(13px);

$h1-font-size: px-to-rem(22px);
$h2-font-size: px-to-rem(16px);
$h3-font-size: px-to-rem(12px);
$h4-font-size: px-to-rem(10px);

$font-size-navbar: px-to-rem(16px);

$font-family-sans-serif: Roboto, system-ui, -apple-system, "Helvetica Neue",
  Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-weight-semibold: 500;

/* SPACING */

$spacer: 8px !default;
$page-padding: 82px;
$half-page-padding: 36px;
$tab-padding: 60px;
$page-bottom-padding: 60px;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  11: $spacer * 6,
  "page": $page-padding,
  "half-page": $half-page-padding,
  "tab": $tab-padding,
  "page-bottom": $page-bottom-padding,
  ) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

/*POPOVER*/

$popover-border-color: $danger;
$popover-border-width: 1.5px;
$popover-arrow-outer-color: $danger;
$popover-font-size: $font-size-base;

/* MODAL */
$modal-sm: 320px;
$modal-backdrop-bg: $white;
$modal-backdrop-opacity: 0.7;

/* NON BOOTSTRAP SECTION */

$body-color: #333333;
$wp-navbar-height: 60px;
$wp-navbar-color: white;
$wp-toolbar-height-collapsed: 80px;
$wp-tabbar-height: 44px;
$wp-toolbar-height-expanded: 176px;
$wp-bottombar-height: 48px;
$wp-toolbar-color: #f2f2f2;
$wp-drawer-width: 42%;
$wp-drawer-width-xl: 33%;
$bar-transition-time: 0.3s;
$elements-transition-time: $bar-transition-time - 0.1s;

/* UTILITY FUNCTIONS */
@function dynamic-theme($opacity: 1) {
  @return rgba(var(--theme), $opacity);
}