@import "../../../styles/variables";

.scenario-row {
  &:nth-of-type(odd) {
    background-color: $odd;
  }

  &:nth-of-type(even) {
    background-color: $even;
  }
}

.scenario-header {
  cursor: pointer;
  border-bottom: 1px solid $separator;
  display: flex;

  &.no-scenario-border {
    border-bottom: 1px solid transparent !important;
  }
}

.header-container {
  &:hover {
    background-color: $primary15;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.scenario-header-section {
  display: flex;
  align-items: center;

  &.cost {
    width: 100px;
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    justify-content: flex-end;
  }
}

.scenario-header-section-path {
  width: 30px;
  justify-content: flex-end;
}

.scenario-header-section-title {
  flex: 1;
  min-width: 0%;

  .title {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.scenario-header-section-money {
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $white;
  background-color: $primary;
  width: 145px;
}

.icon-container {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $primary35;
    border-radius: 50%;
  }
}

.edit-scenario-card {
  background-color: $white;
  border-radius: 14px;
  margin: 12px 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;
}
