@import "../../styles/variables";

.table {
  .row {
    display: flex;
    flex-direction: row;

    .cell:nth-child(1),
    .cell:nth-child(2) {
      flex: 1 1 0%;
    }

    .cell:nth-child(3) {
      width: 60px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .cell {
      padding: 8px 12px;
    }
  }

  .row {
    border-bottom: 1px solid $separator;
  }

  .row.header {
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 1px solid black;
  }
}

.label {
  font-weight: 500;
  text-transform: uppercase;
}
