$areas: (
  "A": (
    "fill": (
      #eff9fb,
      #fdf4fa,
      #f5fcfa,
      #f4eff6,
      #fef7ef,
      #fcfdf2,
      #f5f7fa,
      #fefbfc,
    ),
    "stroke": (
      #92d6e3,
      #eeb2de,
      #bcebdd,
      #b191c3,
      #f8d1ab,
      #eceda9,
      #d2d9e3,
      #f1d8dc,
    ),
  ),
  "B": (
    "fill": (
      #e5f4f7,
      #fbe8f5,
      #ecfaf5,
      #f0e8f5,
      #fdf2e7,
      #f9fbe9,
      #f1f3f7,
      #fbeff1,
    ),
    "stroke": (
      #52b3c9,
      #e362b8,
      #7dd9bd,
      #9c64bc,
      #f2a75b,
      #d6e26e,
      #9fafc5,
      #e3939f,
    ),
  ),
  "C": (
    "fill": (
      #dceff3,
      #fadcef,
      #e2f7f1,
      #ede1f4,
      #fdeede,
      #f6f9e0,
      #e8ecf1,
      #f8e3e6,
    ),
    "stroke": (
      #1191ae,
      #d91391,
      #3ec69d,
      #8736b4,
      #ed891f,
      #bfd732,
      #6781a3,
      #ce4257,
    ),
  ),
  "D": (
    "fill": (
      #dce9ed,
      #f4daeb,
      #e0f0eb,
      #eae0ef,
      #f8eadc,
      #f1f4df,
      #e3e7ec,
      #f1dee2,
    ),
    "stroke": (
      #126a83,
      #b50a78,
      #329a7b,
      #6f3191,
      #d06f17,
      #9db329,
      #465e7c,
      #a0213f,
    ),
  ),
  "E": (
    "fill": (
      #dce3e6,
      #efd9e7,
      #dfe9e6,
      #e6dfe9,
      #f4e6db,
      #ebeede,
      #e0e3e7,
      #ead9df,
    ),
    "stroke": (
      #134457,
      #91005f,
      #256d58,
      #562b6e,
      #b2550f,
      #7b8f1f,
      #30455b,
      #720026,
    ),
  ),
);

$sequential: (
  "A": (
    "fill": (
      // Taken from schema C for areas
      #dceff3,
      #fadcef,
      #e2f7f1,
      #ede1f4,
      #fdeede,
      #f6f9e0,
      #e8ecf1,
      #f8e3e6,
      // Taken from schema A for areas
      #eff9fb,
      #fdf4fa,
      #f5fcfa,
      #f4eff6,
      #fef7ef,
      #fcfdf2,
      #f5f7fa,
      #fefbfc,
      // Taken from schema E for areas
      #dce3e6,
      #efd9e7,
      #dfe9e6,
      #e6dfe9,
      #f4e6db,
      #ebeede,
      #e0e3e7,
      #ead9df,
    ),
    "stroke": (
      // Taken from schema C for areas
      #1191ae,
      #d91391,
      #3ec69d,
      #8736b4,
      #ed891f,
      #bfd732,
      #6781a3,
      #ce4257,
      // Taken from schema A for areas
      #92d6e3,
      #eeb2de,
      #bcebdd,
      #b191c3,
      #f8d1ab,
      #eceda9,
      #d2d9e3,
      #f1d8dc,
      // Taken from schema E for areas
      #134457,
      #91005f,
      #256d58,
      #562b6e,
      #b2550f,
      #7b8f1f,
      #30455b,
      #720026,
    ),
  ),
);

@each $schema-code, $schema in $areas {
  @for $i from 1 to 9 {
    .wpviz-border-a#{$schema-code}#{$i} {
      border-color: nth(map-get($schema, "stroke"), $i);
    }
    .wpviz-stroke-a#{$schema-code}#{$i} {
      stroke: nth(map-get($schema, "stroke"), $i);
    }
    .wpviz-bg-a#{$schema-code}#{$i} {
      background-color: nth(map-get($schema, "fill"), $i);
    }
    .wpviz-fill-a#{$schema-code}#{$i} {
      fill: nth(map-get($schema, "fill"), $i);
    }
    .wpviz-texture-a#{$schema-code}#{$i} {
      background: repeating-linear-gradient(
        45deg,
        #ffffff00,
        #ffffff00 9.5px,
        nth(map-get($schema, "fill"), $i) 11px,
        #ffffff00 12.5px
      );
    }
  }
}

@each $schema-code, $schema in $sequential {
  @for $i from 1 to 25 {
    .wpviz-border-s#{$schema-code}#{$i} {
      border-color: nth(map-get($schema, "stroke"), $i);
    }
    .wpviz-stroke-s#{$schema-code}#{$i} {
      stroke: nth(map-get($schema, "stroke"), $i);
    }
    .wpviz-bg-s#{$schema-code}#{$i} {
      background-color: nth(map-get($schema, "fill"), $i);
    }
    .wpviz-fill-s#{$schema-code}#{$i} {
      fill: nth(map-get($schema, "fill"), $i);
    }
    .wpviz-texture-s#{$schema-code}#{$i} {
      background: repeating-linear-gradient(
        45deg,
        #ffffff00,
        #ffffff00 9.5px,
        nth(map-get($schema, "fill"), $i) 11px,
        #ffffff00 12.5px
      );
    }
  }
}
