@import "../../../styles/variables";

.fragment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $separator;
  padding: 12px 32px 12px 16px;
  cursor: pointer;
  position: relative;

  .customize-handle {
    display: none;
    margin-right: 24px;
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .add-after-handle {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translate(-8px, 8px);
    width: 16px;
    height: 16px;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;

    svg {
      width: 16px;
      height: 16px;
      pointer-events: none;
    }
  }

  &:hover {
    background-color: $gray-bg;
    .customize-handle {
      display: block;
      &:hover {
        background-color: $primary35;
        border-radius: 50%;
      }
    }
  }

  .fragment-title {
    flex: 1 1 0%;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .drag-handle {
    color: $separator;
    cursor: move;
    svg {
      height: 24px;
    }
  }
}

.fill-primary {
  fill: $primary;
}

.line-primary {
  flex: 0 1 80%;
  background-color: $primary;
  height: 2px;
}

.fill-danger {
  fill: $danger;
}

.line-danger {
  flex: 0 1 80%;
  background-color: $danger;
  height: 2px;
}
