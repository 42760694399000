@import "../../styles/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.artwork-container {
  width: 33%;
  background-color: rgba(99, 105, 209, 0.15);
  position: relative;

  .intermediate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.main-container {
  flex: 1 0 0%;
  display: grid;
  grid-template-rows: 40px 1fr 40px;
  grid-template-columns: 1fr;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 28px;
  padding-top: 20px;
  padding-right: 32px;
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 28px;
  padding-bottom: 20px;
  padding-right: 32px;
}

.logo {
  width: 120px;
  height: 36px;
  margin-top: 12px;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;

  label:not(.flat) {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
  cursor: pointer;
  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
