@import "../../styles/variables";

$top-space: $wp-navbar-height + $wp-tabbar-height + 8px;

.column {
  width: 370px;
  flex: 0 0 370px;
  margin: 4px;
  background-color: #ebecf0;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-height: calc(100vh - #{$top-space});

  .children {
    flex: 1;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .title {
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card {
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  overflow: visible;
  border: 2px solid transparent;

  &.card-border-danger {
    border: 2px solid var(--danger);
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .interaction-row {
    margin-top: 4px;
    border-top: 1px solid #ccc;
    padding-top: 2px;
    font-size: .9em;

    .interaction-description {
      font-size: .8em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}