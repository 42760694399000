.cover {
  margin: -1cm;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-size: cover;
  background-position: center;
}

.main {
  padding: 1cm 1cm 0;
}

.footer {
  padding: 0 1cm calc(1cm - 12px);
}
