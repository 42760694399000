@import "../../styles/variables";

.wrapper-disabled {
  background-color: $disabled;
}

.textarea-scroll {
  width: 100%;
  background-color: transparent;
  padding-right: 12px;

  &:active,
  &:focus {
    background-color: transparent;
  }
}
