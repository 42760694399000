.viz-container {
  height: 100px;

  .title {
    width: 150px;
  }

  .texture {
    background: repeating-linear-gradient(
      45deg,
      #ffffff,
      #ffffff 10px,
      rgba(#333333, 0.5) 11px,
      #ffffff 12px
    );
  }

  .first-level-container {
    border: none;
    display: flex;
    flex-direction: row;

    .first-level {
      display: flex;
      flex-direction: row;
      border-style: solid;
      border-width: 3px;

      .inner-level {
        &:not(:last-of-type) {
          border-right-width: 1px;
          border-left-width: 0px;
          border-top-width: 0px;
          border-bottom-width: 0px;
          border-style: solid;
        }
      }
    }
  }
}

.legend-square {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  margin-right: 10px;
}