@import "../../styles/variables";

.dropdown {
  border: none !important;
  padding: 2px 2px 2px 4px !important;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    border: none !important;
    padding: 2px 2px 2px 4px !important;
    background-color: $gray-bg !important;
  }
}

.hide-last:last-of-type {
  display: none;
}