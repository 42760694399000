@import "../../../../../styles/variables";

.task-row {
  &:nth-of-type(odd) {
    background-color: $odd;
  }

  &:nth-of-type(even) {
    background-color: $even;
  }
}

.task-header {
  cursor: pointer;
  border-bottom: 1px solid $separator;
  display: flex;

  &.no-task-border {
    border-bottom: 1px solid transparent !important;
  }
}

.header-container {
  &:hover {
    background-color: $primary15;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
}

.task-header-section {
  display: flex;
  align-items: center;

  &.cost {
    width: 145px;
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    justify-content: flex-end;
  }
}

.task-header-section-path {
  width: 30px;
  justify-content: flex-end;
}

.task-header-section-title {
  flex: 1;
  min-width: 0%;

  .title {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.task-header-section-money {
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  background-color: $primary50;
  width: 145px;

  &.top-level {
    background-color: $primary;
  }
}

.bordered-depth {
  border: 1px solid $white;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  padding: 2px;
  font-size: 10px;
  line-height: 1;
}

.icon-container {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $primary35;
    border-radius: 50%;
  }
}

.not-visible {
  visibility: hidden;
  pointer-events: none;
}

.visible {
  visibility: visible;
  pointer-events: all;
}

.move-arrow {
  color: $separator;

  &:hover {
    color: $black !important;
  }
}

.depth-dot {
  margin: 1px 0;
}

.edit-task-card {
  background-color: $white;
  border-radius: 14px;
  margin: 12px 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;

  .task-info {
    width: 40%;
  }

  .resource-edit {
    min-width: 600px;
    width: 60%;
  }

  @media screen and (max-width: 1800px) {
    flex-direction: column;
    align-items: center;

    .task-info {
      width: 100%;
    }

    .resource-edit {
      min-width: 0px;
      width: 100%;
    }
  }
}
