@import '../../styles/variables';

// .profile-grid {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-template-rows: repeat(11, auto);
//   grid-column-gap: 55px;
//   justify-items: end;

//   h3 {
//     grid-column: span 2;
//     justify-self: center;
//   }

//   label,
//   .label {
//     &::after {
//       content: ":";
//     }
//   }
// }

// .input-size {
//   width: 250px;
// }

.save-bar {
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  position: fixed;
  background-color: $gray-bg-light;
}
