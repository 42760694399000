@import "../../styles/variables";

.table {
  & > div {
    // groups of rows
    &:nth-child(2n + 1) {
      background-color: $gray-light;
    }
    & > div {
      // rows
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > div {
        padding: 8px 12px 0 12px;
      }

      & > div:nth-child(1) {
        flex: 3 3 0%;
        min-width: 0;
      }
      & > div:nth-child(2) {
        flex: 2 2 0%;
        min-width: 0;
      }
      & > div:nth-child(3) {
        flex: 1 1 0%;
        min-width: 0;
      }
      & > div:nth-child(4) {
        flex: 2 2 0%;
        min-width: 0;
        text-align: right;
      }
      &:not(:first-child) {
        font-size: 90%;
        font-style: italic;
        & > div {
          padding: 2px 12px;
        }
        & > div:nth-child(1)::before {
          content: '';
          display: inline-block;
          width: 30px;
        }
      }
      &:last-child {
        & > div {
          padding-bottom: 8px;
        }
      }
    }
  }
}
