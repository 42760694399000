@import "../../styles/variables";

.min-screen-size-required {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $gray-bg;
  text-align: center;
  z-index: 99999;
}

.message {
  max-width: 400px;
}

.logo {
  margin-bottom: 24px;
}