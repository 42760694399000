@import "../../../../styles/variables";

.main-task-element {
  stroke: $separator;
  stroke-width: 2;
  fill: $white;
  stroke-linecap: round;
}

.resource-task-element {
  fill: $white;
  stroke-linecap: round;
}

.resource-task-element-fill {
  stroke: none;
  fill: $white;
}

.resource-description {
  pointer-events: none;
}

.atomic-block {
  page-break-inside: avoid;
}

.legend {
  width: 100%;
  td {
    vertical-align: middle;
    padding: 4px 0;
  }
}

.legend-square {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  margin-right: 10px;
}