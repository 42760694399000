@import "../../styles/variables";

.image-input-container {
  width: 50px;
  height: 50px;
  border: 1px solid $black;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  input {
    display: none;
  }

  .overlay {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $primary50;
    color: $white;

    svg {
      width: 19px;
      height: 19px;
    }
  }

  &.circle {
    border-radius: 50%;
  }

  &.square {
    border-radius: 5px;
  }

  &:hover {
    .overlay {
      visibility: visible;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
