@import "../../styles/variables";

.dropdown,
.dropdown:hover,
.dropdown:focus,
.dropdown:active {
  width: 100%;
  border-radius: 6px !important;
  background-color: $white !important;
  color: $black !important;
  border: 1px $separator solid !important;
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-item:active {
  background-color: $primary50 !important;
  outline: none !important;
  border: none !important;
}

.wp-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown,
.dropdown:hover,
.dropdown:focus,
.dropdown:active {
  &.disabled {
    background-color: #f9f9f9 !important;
    color: #333 !important;
  }
  &.invalid {
    background-color: rgba($danger, .35) !important;
  }
}

.placeholder {
  color: #aaa;
}