.templating-popover {
  position: relative;
}

.templating-popup {
  position: absolute;
  z-index: 2;
  background-color: white;
  min-width: 200px;
  box-shadow: rgba(0, 0, 0, .15) 0px 0px 0px 1px, rgba(0, 0, 0, .15) 0px 8px 16px;
  border-radius: 4px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 7px;

  &.bottom-picker {
    bottom: 100%;
  }

  &.top-picker {
    top: calc(100% + 5px);
  }

  &.left-picker {
    left: 5px;
  }

  &.right-picker {
    right: 0;
  }

  .templating-clickable {
    padding: 2px 5px;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }

  .templating-not-clickable {
    padding: 2px 5px;
  }
}