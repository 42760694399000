@import "../../styles/variables";

.plan-card {
  border-radius: 10px;
  width: 250px;
  box-shadow: 0 4px 7px 0 rgba(51, 51, 51, 0.2);
  border: 1px solid $primary;
}

.plan-card-top {
  background-color: $primary10;
}

.price-title {
  font-size: 34px;
}