@import '../../../../styles/variables';

.link {
    stroke: dynamic-theme();
    stroke-width: 1;
    fill: none;
}

.leaf-node, .parent-node, .root-node {
    fill: dynamic-theme();
    stroke-width: 0;
}

.leaf-node-stroke {
    fill: dynamic-theme();
    font-size: 13px;
}

.depth-1 {
    font-weight: 600;
}