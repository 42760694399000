@import "../../styles/variables";

$inputWidth: 250px;

.wp-input {
  border-radius: 10px;
  border: $border-width solid $separator;

  &.tiny {
    border-radius: 6px;
  }

  &.medium {
    border-radius: 6px;
    padding: 3px 6px;
  }

  &:not([disabled]):active,
  &:not([disabled]):focus {
    background-color: $primary15;
    border: $border-width solid transparent !important;
  }

  &.clear {
    background-color: transparent;
    border: $border-width solid transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary10;
      border: $border-width solid transparent;
    }
  }

  &.pill {
    width: $inputWidth;
    border-radius: $inputWidth / 2;
  }

  &[disabled] {
    background-color: $disabled;
  }
}