@import "../../styles/variables";

.date-today {
  color: $primary;
}

.date-selected {
  background-color: $primary35;
}

.date-generic {
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
}

.date-alerts {
  color: $danger;
}

/* NEW */
.calendar-cell {
  flex: 1;
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  background-color: $white;
  border: 1px solid $gray-light;
  padding: 8px;
  cursor: pointer;
}

.calendar-cell:hover {
  background-color: #f8f8f8;
}

.calendar-row:first-child .calendar-cell:first-child {
  border-top-left-radius: $border-radius;
}

.calendar-row:first-child .calendar-cell:last-child {
  border-top-right-radius: $border-radius;
}

.calendar-row:last-child .calendar-cell:first-child {
  border-bottom-left-radius: $border-radius;
}

.calendar-row:last-child .calendar-cell:last-child {
  border-bottom-right-radius: $border-radius;
}

.calendar-cell-day {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary10;
  border-radius: 40px;
  font-weight: 700;
}

.calendar-badge {
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 0.95;
  text-align: center;
}

.legend-color-showcase {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.detail-navi-grid {
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
}