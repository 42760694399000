@import "../../styles/variables";

.costs-table-row {
  .delete-row-action {
    display: none;
  }

  .error-icon {
    display: block;
  }

  &:hover {
    background-color: $primary10;
    border-radius: 17.3px;
    border-bottom: $border-width transparent solid !important;

    .delete-row-action {
      display: block;
    }

    .error-icon {
      display: none;
    }
  }

  input {
    background-color: transparent;
    border-radius: 6px;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary10;
    }
  }
}

.page-viewport {
  padding-right: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 11px;
}

.bordered {
  border-radius: 10px;
  border: $border-width solid $separator;

  &.wp-textarea:focus-within {
    background-color: $primary15;
    border: $border-width solid transparent !important;
  }

  &.wp-input {
    &:active,
    &:focus {
      background-color: $primary15;
      border: $border-width solid transparent !important;
    }
  }
}
