@import "../../styles/variables";

.wp-switch {
  &.rc-switch {
    width: 35px;
    height: 16px;
    border: none;
    
    &.rc-switch-checked {
      border: none;
      background-color: $primary50;
      margin: 0 7px;

      &::after {
        left: 16px;
        background-color: $primary;
        border: 2px solid transparent;
      }
    }

    &::after {
      width: 24px;
      height: 24px;
      top: -4px;
      left: -5px;
      border: 2px solid $primary;
    }
  }
}
